<template>
  <main>
    <package-header title="Electronic Instrument" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">

        <div class="col">

          <div class="d-sm-flex justify-content-between align-items-start mb-3 pb-3 border-bottom">
            <div>
              <!-- Back to applications link -->
              <router-link class="mr-2" to="/pages/applications">
                <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
                <span class="d-none d-md-inline-block">Return to Applications</span>
                <span class="d-md-none">Back</span>
              </router-link>

              <!-- Change instrument dropdown -->
              <a href="javascript:void(0)" class="btn btn-md btn-secondary dropdown-toggle d-sm-inline-block" id="instrument" data-toggle="dropdown" aria-controls="instrument-contents" aria-expanded="false">
                Change Application
                <span class="caret"></span>
              </a>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="javascript:void(0)">01 - Transfer Caso <span class="badge badge-success">E-signed</span></a>
                <a class="dropdown-item font-weight-bold" href="javascript:void(0)">02 - A1 release</a>
                <a class="dropdown-item" href="javascript:void(0)">03 - Mortgage Caso</a>
                <a class="dropdown-item" href="javascript:void(0)">04 - A2 release</a>
                <a class="dropdown-item" href="javascript:void(0)">05 - Application with longer name</a>
              </div>
            </div>

            <a href="javascript:void(0)" class="d-flex flex-auto align-items-center justify-content-end mt-2 mt-sm-0">
              <span class="text-right line-height-sm">E-sign Next Application:<br><strong>A2 release</strong></span>
              <svg class="icon icon-lg icon-signature ml-2"><use xlink:href="/icons/symbol-defs.svg#icon-signature"></use></svg>
            </a>
          </div>

          <!-- Package title and actions -->
          <div class="d-md-flex align-items-start justify-content-between">
            <div>
              <h2 class="page-title mr-2">A1 release</h2>
              <ul class="list-inline mb-1">
                <li class="list-inline-item">
                  <small><span class="text-muted text-small">status:</span> <strong>Drafted</strong></small>
                </li>
                <li class="list-inline-item">
                  <small><span class="text-muted">Modified:</span> <strong>Sep 22, 2018 at 5:33 PM</strong></small>
                </li>
              </ul>
            </div>

            <button class="btn btn-md btn-secondary d-md-none mb-2" type="button" data-toggle="collapse" data-target="#application-nav" aria-expanded="false" aria-controls="application-nav">
              <svg class="icon icon-more"><use xlink:href="/icons/symbol-defs.svg#icon-more"></use></svg>
              <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
              Navigate Application
            </button>
          </div>

          <!-- Main package content -->
          <div class="row mt-md-3">

            <!-- Step wizard -->
            <div class="col-md-3 mb-2 mb-md-4">
              <div class="collapse collapse-md" id="application-nav">
                <div class="step-wizard">
                  <div class="step completed">
                    <router-link class="step-link" to="/pages/edit-application/applicant">Applicant</router-link>
                  </div>
                  <div class="step completed">
                    <router-link class="step-link" to="/pages/edit-application/charges-liens-interests">Charge, Lien or Interest</router-link>
                  </div>
                  <div class="step completed">
                    <router-link class="step-link" to="/pages/edit-application/pid-legal-description">Description of Land</router-link>
                  </div>
                  <div class="step completed">
                    <a class="step-link" href="javascript:void(0)">Transferor</a>
                  </div>
                  <div class="step completed">
                    <router-link class="step-link" to="/pages/edit-application/execution">Execution</router-link>
                  </div>
                  <div class="step completed">
                    <a class="step-link" href="javascript:void(0)">Attachment for Supporting Evidence</a>
                  </div>
                  <div class="step completed">
                    <a class="step-link" href="javascript:void(0)">Validation Summary</a>
                  </div>
                  <div class="step active">
                    <router-link class="step-link" to="/pages/electronic-instrument">View Electronic Instrument</router-link>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-9">
              <h2 class="mb-3">Electronic Instrument</h2>
              <h5 class="mt-3 mb-1">Review this instrument and scroll to the bottom to E-sign.</h5>
              <p class="mb-0"><small>This Electronic Instrument is a preview of the form that will be printed, signed, and submitted to the Land Title Office.

                <a href="#" data-toggle="popover" data-placement="top" data-title="Need to make a change?" data-content="Go to the left panel and click the related section." v-popover>
                  <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                </a>
              </small></p>

              <p><small>If you are having trouble seeing the instrument in your browser, you can <a href="javascript:void(0)">download the instrument instead</a>.</small></p>

              <!-- Tabs Content  -->
              <div class="border p-3">
                <img class="print-preview text-center mb-3" src="">
              </div>

              <div class="d-flex align-items-start justify-content-between mt-2">
                <button class="btn btn-md btn-secondary">Download</button>
                <a href="javascript:void(0)" class="d-flex align-items-center">
                  <span class="text-right">Continue to Next Application:<br><strong>A2 release</strong></span>
                  <svg class="icon icon-arrow_cta ml-2"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use></svg>
                </a>
              </div>

              <div class="mt-4 p-2 p-md-4 bg-medium">
                <div class="d-sm-flex align-items-center mb-3">
                  <h2 class="mb-0">E-Sign</h2>
                  <a class="collapse-toggle ml-sm-2" href="#collapseExample2" data-toggle="collapse" aria-expanded="false" aria-controls="collapseExample2">
                    <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                    <svg class="icon icon-close-circle"><use xlink:href="/icons/symbol-defs.svg#icon-close-circle"></use></svg>
                    About the E-sign process
                  </a>
                </div>

                <div class="collapse" id="collapseExample2">
                  <div class="card bg-white mb-2">
                    <div class="card-body">
                      <h4>This is an example of a help text collapse.</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet maximus nibh, nec rhoncus tellus. Aenean lobortis lacinia odio sed consequat. Cras congue elit eget dolor aliquam dignissim.</p>
                    </div>
                  </div>
                </div>

                <form submit.prevent class="d-print-none">
                  <div class="row">
                    <div class="col-md-10 col-lg-8">
                      <div class="form-group">
                        <label for="certificate" class="control-label">
                          Select your digital certificate:
                          <a href="#" data-toggle="popover" data-placement="top" data-title="Help Text" data-content="This is an example of some help text" aria-describedby="popover96323">
                            <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                          </a>
                        </label>
                        <div class="radio-btn-group">
                          <div class="radio-btn">
                            <input id="certificate1" name="certificate" type="radio" class="radio" checked>
                            <label for="certificate1">
                              Jane Smith<br>
                              ASR
                            </label>
                          </div>
                          <div class="radio-btn">
                            <input id="certificate2" name="certificate" type="radio" class="radio">
                            <label for="certificate2">
                              Jane Smith<br>
                              Miller Thomson
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group mt-4">
                        <label for="password">Enter your Juricert digital certificate password:</label>
                        <input class="form-control" type="password" name="password" id="password">
                        <div id="forgotPassword">
                          <button class="btn collapse-btn collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            <svg class="icon icon-arrow_right collapse-icon"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use></svg>
                            <small>Forgot Your Password?</small>
                          </button>
                          <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#forgotPassword">
                            <div class="collapse-body">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin et ligula eget tortor faucibus laoreet sed a nunc. Nulla facilisi. Ut fringilla pellentesque urna, non facilisis nisi euismod eget.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group mt-3">
                        <p>Agree to the following certification statement:</p>
                        <div class="custom-control custom-checkbox align-items-middle">
                          <input type="checkbox" class="custom-control-input" id="customCheck1">
                          <label class="custom-control-label" for="customCheck1"><small>Your electronic signature is a representation that you are a subscriber as defined by the Land Title Act, RSBC 1996 c.250, and that you are applying your electronic signature in accordance with Section 168.3, and a true copy, or a copy of that true copy, is in your possession.</small></label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group d-md-flex justify-content-between align-items-start mt-3">
                    <span>
                      <button type="submit" class="btn btn-primary disabled">E-sign</button>
                      <button type="submit" class="btn btn-link">Return to Applications</button>
                    </span>
                    <a href="javascript:void(0)" class="d-flex justify-content-end flex-md-auto align-items-center mt-3 mt-md-0">
                      <span class="text-right line-height-sm">E-sign Next Application:<br><strong>A2 release</strong></span>
                      <svg class="icon icon-lg icon-signature ml-2"><use xlink:href="/icons/symbol-defs.svg#icon-signature"></use></svg>
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Electronic Instrument"></help-panel>
  </main>

</template>

<script>

import PackageHeader from '../../../components/PackageHeader.vue'
import PackageNavigation from '../../../components/PackageNavigation.vue'
import HelpPanel from '../../../components/HelpPanel.vue'

export default {

  components: {
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel
  },

  data () {
    return {

    }
  },
  methods: {

  }
}
</script>

